.landingPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--background-color);
}

.topSection {
  text-align: center;
  margin-bottom: 2rem;
}

.largeText {
  font-size: 3rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.subtitleText {
  font-size: 1.25rem;
  color: var(--grey-color);
}

.recipeListSection {
  width: 100%;
}
