/* EditRecipe.module.css */

/* Container */
.recipe-container {
    max-width: 80rem;
    margin: 2.5rem auto;
    padding: 1.5rem;
    background-color: var(--background-color);
    position: relative;
  }
  
  /* Header */
  .recipe-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Toggle Switch */
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 34px;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-switch span {
    margin-left: 10px;
    font-size: 14px;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: var(--secondary-color);
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .toggle-label {
    font-size: 14px;
    padding-left: 10px;
  }
  
  /* Title */
  .recipe-title {
    font-size: 2rem;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
  }
  
  /* Form */
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-input,
  .form-textarea,
  .form-select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0;
    transition: border-color 0.2s, box-shadow 0.2s;
  }
  
  .form-input:focus,
  .form-textarea:focus,
  .form-select:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px var(--primary-color);
  }
  
  .form-button {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--secondary-color);
    color: #fff;
    border: none;
    border-radius: 0;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .form-button:hover {
    background-color: var(--secondary-light-color);
  }
  
  /* For Delete Button */
  .bg-danger {
    background-color: #ef4444;
  }
  
  .bg-danger:hover {
    background-color: #dc2626;
  }
  
  /* Ingredients Section */
  .ingredients-title {
    font-size: 1.6rem;
    color: var(--primary-color);
  }
  
  .ingredients-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  
  .ingredient-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    border: 1px solid #ddd;
    background-color: var(--background-color);
    transition: background-color 0.3s ease;
    cursor: pointer;
    width: 100%;
  }
  
  .ingredient-card:hover {
    background-color: #f0f0f0;
  }
  
  .ingredient-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .ingredient-info-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    text-align: left;
  }
  
  .ingredient-text {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .product-name {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .product-price {
    font-size: 0.9rem;
    color: #555;
    margin-top: 0.25rem;
  }
  
  .ingredient-store-logo {
    width: 3rem;
    height: 3rem;
    object-fit: contain;
    margin-right: 1rem;
    flex-shrink: 0;
  }
  
  .ingredient-product-image {
    width: 7rem;
    height: 7rem;
    object-fit: contain;
    margin-left: 1rem;
    flex-shrink: 0;
    border-radius: 0;
  }
  
  .add-ingredient-card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-size: 1.5rem;
    cursor: pointer;
    height: 8rem;
  }
  
  .add-ingredient-text {
    margin-left: 0.5rem;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .add-ingredient-icon {
    font-weight: bold;
    color: var(--primary-color);
  }
  
  /* Ingredient Actions */
  .ingredient-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
    gap: 0.5rem;
  }
  
  .edit-ingredient-button,
  .delete-ingredient-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  .edit-ingredient-button {
    background-color: var(--secondary-color);
    color: #fff;
  }
  
  .edit-ingredient-button:hover {
    background-color: var(--secondary-light-color);
  }
  
  .delete-ingredient-button {
    background-color: #ef4444;
    color: #fff;
  }
  
  .delete-ingredient-button:hover {
    background-color: #dc2626;
  }
  
  /* Popup */
  .popup-container {
    position: fixed;
    inset: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 2.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 !important;
  }
  
  .popup-content {
    background-color: #fff;
    padding: 1.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80rem;
    max-height: 75vh;
    overflow-y: auto;
    border-radius: 0;
  }
  
  .popup-content input,
  .popup-content select,
  .popup-content .search-input {
    width: 100%;
    margin-bottom: 1.5rem;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 0;
    transition: border-color 0.2s, box-shadow 0.2s;
  }
  
  .popup-content input:focus,
  .popup-content select:focus,
  .popup-content .search-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px var(--primary-color);
  }
  
  .popup-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
  }
  
  .popup-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
  }
  
  .popup-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    border: 1px solid #ddd;
    background-color: #fff;
    height: 170px;
    cursor: pointer;
    transition: transform 0.2s;
    width: 100%;
  }
  
  .popup-product:hover {
    transform: scale(1.05);
  }
  
  .popup-product-title {
    height: 40%;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .popup-product-bottom {
    display: flex;
    justify-content: space-between;
    height: 60%;
    width: 100%;
  }
  
  .popup-product-price {
    width: 40%;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
  }
  
  .popup-store-logo {
    width: 12%;
    height: 100%;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-product-image {
    width: 40%;
    height: 100%;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .selected-product {
    border: 4px solid var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: border 0.3s ease-in-out;
  }
  
  .search-input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    border: 1px solid #cbd5e0;
    background-color: #f0f0f0;
    border-radius: 0;
    transition: border-color 0.2s;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ef4444;
    color: #fff;
    padding: 0.25rem 0.75rem;
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-size: 18px;
  }
  
  .close-button:hover {
    background-color: #dc2626;
  }
  
  .done-button {
    position: absolute;
    bottom: 50px;
    right: 50%;
    transform: translateX(50%);
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .done-button:hover {
    background-color: var(--primary-color-dark);
  }
  
  .amount-unit-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .amount-unit-container .form-input {
    flex: 2;
  }
  
  .amount-unit-container .form-select {
    flex: 1;
  }
  
  /* Pagination Controls */
  .pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    margin: 0 5px;
    padding: 5px 10px;
    color: var(--primary-color);
    cursor: pointer;
  }
  
  .pagination-button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .active-page {
    color: var(--primary-color);
    font-weight: bold;
  }
  
  /* Store Filter */
  .store-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .store-checkbox {
    display: inline-flex;
    margin: 0;
    padding: 3px 5px;
    font-size: 0.85em;
  }
  
  .store-checkbox input {
    margin-right: 5px;
    transform: scale(0.85);
    vertical-align: middle;
  }
  
  .store-checkbox label {
    display: flex;
    align-items: center;
  }
  
  /* Existing Recipe Image */
  .existing-recipe-image {
    width: 100%;
    max-width: 300px;
    margin-top: 1rem;
    border: 1px solid #ddd;
    border-radius: 0;
  }
  
  @media (max-width: 768px) {
    .ingredients-grid {
      grid-template-columns: 1fr;
    }
  
    .popup-content {
      width: 100%;
      padding: 1rem;
    }
  
    .popup-grid {
      grid-template-columns: 1fr;
    }
  
    .done-button {
      bottom: 20px;
    }
  }
  