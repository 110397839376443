.App {
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  min-height: 100vh; 
}

main {
  width: 100%;
  max-width: 1800px; 
  padding: 20px;
  box-sizing: border-box; 
  margin: 0 auto;
}

html, body {
    background-color: var(--background-color); 
    margin: 0;
    padding: 0;
    min-height: 100vh; 
    font-family: 'Playfair Display', serif;
    box-sizing: border-box;
    overflow-x: hidden;
  }
