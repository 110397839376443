.recipe-container {
  max-width: 80rem;
  margin: 2.5rem auto;
  padding: 1.5rem;
  background-color: var(--background-color);
  position: relative;
  width: 100%;
}

.edit-button-container {
  display: flex;
  justify-content: flex-end; 
  margin-top: 1rem; 
  margin-bottom: 1.5rem;
}

.edit-button {
  background-color: var(--secondary-color);
  color: var(--background-color); 
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 1rem;
}

.delete-button {
  background-color: red;
  color: var(--background-color); 
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
}

.save-button .material-icons {
  font-size: 50px !important; 
}

.recipe-top-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4rem;
  width: 100%;
  height: 25rem;
}

.recipe-info-container {
  width: 45%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  height: 100%;
}

.recipe-image-container {
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
  position: relative;
  height: 100%;
}

.recipe-image {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
}

.recipe-tags {
  margin-bottom: 6rem; 
}

.recipe-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  flex-grow: 1; 
}

.tags-container {
  display: flex;
  gap: 10px; 
}

.recipe-tag {
  background-color: var(--primary-color);
  border-radius: 0;
  padding: 5px 10px;
  font-size: 0.9rem;
  color: var(--background-color);
}

.save-button .material-icons {
  font-size: 50px !important; 
}

.recipe-title {
  font-size: 3rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.recipe-description {
  font-size: 1.25rem;
  color: #4a5568;
  margin-top: 10px;
  text-align: left;
}

/* Voting Section */
.voting-section {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
}

.vote-button {
  padding: 0.5rem;
}

.active-vote {
  color: var(--secondary-color);
}

.vote-count {
  padding: 0 1rem;
  font-size: 1.125rem;
}

/* Tags */
.recipe-tags {
  margin-bottom: 1.5rem;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.recipe-tag {
  background-color: var(--primary-color);
  color: white;
  padding: 0.25rem 0.5rem;
}

/* Recipe Instructions */
.recipe-instructions-container {
  margin-bottom: 1.5rem;
}

.ingredient-card {
  display: flex;
  background-color: var(--primary-color);
  padding: 0;
  transition: background-color 0.3s ease;
  height: 150px; 
}

.ingredient-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.recipe-instructions-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.recipe-instructions-text {
  color: #4a5568;
}

/* Ingredients Grid */
.ingredients-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 1.5rem;
  width: 100%;
  margin-bottom: 2rem;
}

/* Ingredient Card */
.ingredient-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ddd;
  background-color: var(--background-color);
  transition: background-color 0.3s ease;
  cursor: pointer;
  width: 100%;
  max-height: 8rem;
}

.ingredient-card:hover {
  background-color: #f0f0f0; 
}

/* Ingredient Card Content */
.ingredient-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Ingredient Info (Left) */
.ingredient-info-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
}

/* Ingredient Text */
.ingredient-text {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-family: 'Roboto', sans-serif;
  color: var(--grey-color);
}

.ingredients-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

/* Product Name */
.product-name {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: var(--grey-color);
}

/* Product Price */
.product-price {
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  color: #555;
}

/* Store Logo */
.ingredient-store-logo {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
  margin-right: 1rem;
  flex-shrink: 0; 
}

/* Product Image */
.ingredient-product-image {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  margin-left: 1rem;
  flex-shrink: 0; 
}

/* No Ingredients Placeholder */
.no-ingredients {
  font-size: 1.125rem;
  color: #4a5568;
  text-align: center;
}

  /* Total Price */
.total-price-container {
  margin-top: 6rem;
  text-align: left;
}
  
.total-price {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: var(--background-color);
  background: var(--secondary-color);
  text-align: left;
  display: inline;
  padding: 0.2rem;
}
  
  /* Popup */
.popup-container {
  position: fixed;
  inset: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 2.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0 !important;
}

.popup-content {
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 62rem;
  width: 100%;
  max-height: 75vh;
  overflow: visible;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0 !important;
  position: relative;
}

.popup-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.popup-store-logo {
  width: 3.5rem;  
  height: 3.5rem;  
  object-fit: contain;
  align-self: center;  
}

.popup-product {
  width: 14rem;
  height: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;
  padding: 0.5rem;
  border: 1px solid #ddd;
}

.popup-product:hover {
  transform: scale(1.05);
}

.popup-product-image {
  width: 10rem; 
  height: 10rem; 
  object-fit: contain;
  margin-bottom: 10px;
}

.product-info {
  display: flex;
  justify-content: space-between;
  align-items: left;
  width: 100%;
  padding: 0 10px;
}

.product-info-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%; 
}

.popup-product-name {
  font-size: 1.2rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 60%;  
}

.popup-product-price {
  font-size: 0.9rem;
  height: 1.5rem; 
}

.search-input {
  width: 100%; 
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #cbd5e0;
  background-color: #f0f0f0; 
  border-radius: 0 !important; 
}

.close-button {
  position: absolute;
  top: 10px; 
  right: 10px;
  background-color: #ef4444;
  color: #fff;
  padding: 0.25rem 0.75rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 18px;
}

.close-button:hover {
  background-color: #dc2626;
}

.no-recipe {
  font-size: 1.25rem;
  color: #e53e3e;
  text-align: center;
}

.stored-price-container {
  margin-top: 1.5rem;
  text-align: center;
}

.stored-price {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--primary-color);
}

.save-button {
  position: absolute;
  top: 0px;
  right: 25px;
  background: var(--background-color);
  font-size: 40px;
  cursor: pointer;
  color: var(--secondary-color); 
  z-index: 1;
  width: 50px;
  height: 60px;
  padding-top: 8px;
}

.save-button.saved {
  color: var(--secondary-color); 
}

.save-button:hover {
  color: var(--secondary-color);
}