    .recipe-list-container {
        background-color: var(--background-color);
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .search-filters {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        margin-bottom: 24px;
        width: 100%; 
        max-width: 1800px;
        box-sizing: border-box;
    }

    .recipe-list-wrapper {
        width: 100%;
        max-width: 1800px;
        padding: 24px;
        text-align: center; 
        min-height: 300px;
    }

    .recipe-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
        width: 100%;
        max-width: 1600px; 
        box-sizing: border-box;
        margin: 0 auto;
        min-width: 1600;
    }


    .no-recipes {
        font-size: 1.2rem;
        color: var(--text-secondary-color);
    }

    .pagination {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        gap: 8px;
    }

    .pagination-button {
        display: flex;
        justify-content: center; 
        align-items: center;    
        width: 40px;             
        height: 40px;            
        border-radius: 50%;      
        background-color: var(--background-color) !important;
        color: var(--primary-color);
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .pagination-button:hover {
        background-color: var(--primary-light-color);
    }

    .pagination-button.active {
        color: var(--primary-color);
    }

    .error-message {
        color: red;
        font-size: 1rem;
    }

    .search-filters {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px; 
        margin-bottom: 24px;
    }

    .search-filters input,
    .search-filters select {
        padding: 10px;
        font-size: 1rem;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        transition: border-color 0.3s ease;
        border-radius: 0;
    }

    .search-filters input:focus,
    .search-filters select:focus {
        outline: none;
        border-color: var(--primary-light-color);
    }

    .search-filters button {
        padding: 10px 20px;
        background-color: var(--primary-color);
        color: var(--background-color);
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 0;
    }

    .search-filters button:hover {
        background-color: var(--primary-light-color);
    }

    .subtle-error {
        color: red;
        font-size: 0.9rem;
        margin-top: 10px;
    }
    
    .recipe-card:hover {
        background-color: var(--primary-light-color); 
    }
    
    .recipe-card {
        border: 1px solid var(--primary-color);
        height: 170px; 
        display: flex;
        justify-content: space-between;
        transition: background-color 0.3s ease;
        overflow: hidden;
        min-height: 170px;
    }
    
    .recipe-details {
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: center;
        padding: 20px;
        flex: 1; 
        max-width: 60%;
        min-width: 60%;
    }
    
    .recipe-title {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 10px;
        color: var(--primary-color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
    
    .recipe-description {
        font-size: 1rem;
        margin-bottom: 5px;
        color: var(--primary-color);
        text-overflow: ellipsis;
        align-self: flex-start;
    }

    .recipe-price {
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 5px;
        color: var(--background-color);
        background: var(--secondary-color);
        text-overflow: ellipsis;
        align-self: flex-start;
        font-family: 'Roboto', sans-serif;
        padding: 0.2rem;
    }

    .recipe-image-wrapper {
        position: relative;
        width: 100%;
        aspect-ratio: 1 / 1; 
        overflow: hidden;
    }

    .recipe-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .recipe-card-link {
        text-decoration: none;
        color: inherit; 
    }

    .recipe-card-link:hover .recipe-card {
        background-color: var(--primary-light-color);
    }

    @media (max-width: 600px) {
        .recipe-card {
            flex-direction: column;
            height: auto;
        }

        .recipe-details,
        .recipe-image-wrapper {
            flex: none;
            max-width: 100%;
            width: 100%;
        }

        .recipe-image {
            width: 100%;
            height: auto;
        }
    }
