/* Container */
.view-products-container {
    padding: 20px;
  }
  
  /* Title */
  .title {
    color: var(--primary-color);
    text-align: left;
    font-size: 2rem;
    padding-bottom: 2rem;
    font-weight: 700;

  }
  
  /* Search Filters */
  .search-filters {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .search-input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid var(--primary-color);
    border-radius: 0;
  }
  
  .store-filter {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .store-checkbox {
    display: flex;
    align-items: center;
  }
  
  .store-checkbox input {
    margin-right: 5px;
  }
  
  /* Products Grid */
  .products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
    gap: 16px;
    margin-top: 20px;
    justify-content: center;
  }
  
  /* Product Card */
  .product-card {
    border: 1px solid var(--primary-color);
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 345px; 
    width: 345px; 
    box-sizing: border-box;
  }
  
  .product-card:hover {
    background-color: var(--primary-light-color);
  }
  
  .selected-product {
    border: 2px solid var(--accent-color);
  }
  
  /* Product Title */
  .product-title {
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--primary-color);
    text-align: center;
    height: 40px; /* Fixed height */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  /* Product Details */
  .product-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
  }
  
  /* Store Logo */
  .store-logo {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    object-fit: contain;
  }
  
  /* Product Image Container */
  .product-image-container {
    width: 100%;
    height: 180px; 
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
  /* Product Image */
  .product-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  /* Product Price */
  .product-price {
    font-size: 1.1rem;
    color: var(--primary-color);
    margin-top: auto;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;

  }
  
  /* Pagination Controls */
  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 10px 20px;
    margin: 0 10px;
    background-color: var(--gray-200);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pagination-button:hover {
    background-color: var(--primary-light-color);
  }
  
  .current-page-info {
    font-size: 1rem;
  }
  
  /* Selected Products Section */
  .selected-products-section {
    margin-top: 40px;
  }
  
  .section-title {
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .selected-products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
    justify-content: center;
  }
  
  .selected-product-card {
    border: 1px solid var(--primary-color);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 350px; 
    width: 200px; 
    box-sizing: border-box;
  }
  
  .total-price {
    text-align: right;
    font-size: 1.5rem;
    margin-top: 20px;
    color: var(--primary-color);
  }
  
  /* Responsive Design */
  @media (max-width: 800px) {
    .products-grid,
    .selected-products-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
  
  @media (max-width: 500px) {
    .products-grid,
    .selected-products-grid {
      grid-template-columns: 1fr;
    }
  }
  