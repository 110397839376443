.bgPrimary {
    background-color: var(--background-color); 
    border-bottom: 2px solid var(--primary-color);
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 84px;
  }
  
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  
  .logo {
    flex-grow: 1;
  }
  
  .logoText {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .nav {
    display: flex;
    align-items: center;
  }
  
  .headerLink {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: var(--primary-color);
    text-decoration: none;
    overflow: hidden;
    transition: color 0.5s ease;
    z-index: 1;
  }
  
  .headerLink:hover {
    text-decoration: underline;
    text-decoration-color: var(--secondary-color);
  }
  
  .loginButton {
    display: flex;
  }
  
  .textSecondary {
    color: var(--secondary-color);
  }
  
  .divider {
    height: 40%; 
    width: 1px; 
    background-color: var(--primary-color);
    color: var(--primary-color);
    margin: 0 20px; 
  }

  .body-content {
    margin-top: 70px; 
    padding: 20px;
    box-sizing: border-box;
  }

.dropdownContainer {
  position: relative;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 150px;
}

.dropdownItem {
  display: block;
  padding: 10px 15px;
  color: var(--text-color);
  text-decoration: none;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: var(--primary-light-color);
}

.headerLink {
  position: relative;
  padding: 10px 15px;
  color: var(--text-color);
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}

.headerLink span {
  position: relative;
  z-index: 1;
}

.headerLink::before {
  content: '';
  position: absolute;
  top: var(--y, 0);
  left: var(--x, 0);
  transform: translate(-50%, -50%) scale(var(--scale, 0));
  background-color: var(--primary-light-color);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  opacity: 0.5;
  transition: transform 0.5s, opacity 0.5s;
  pointer-events: none;
}

.headerLink.active::before {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
}

@media (max-width: 768px) {
  .dropdownMenu {
    right: 10px;
  }
}
