.footer {
  background-color: var(--background-color);
  color: var(--primary-color);
  padding: 1rem 0;
  border-top: 1px solid var(--border-color);
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap; 
}

.contact, .social, .message {
  flex: 1; 
  text-align: center;
  padding: 0 1rem;
}

h3 {
  font-size: 1rem;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
}

p, a {
  font-size: 0.875rem;
  color: var(--primary-color);
}

a:hover {
  color: var(--primary-color-dark);
}

.socialLinks {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center; 
  gap: 0.5rem;
}

.socialLinks a {
  text-decoration: none;
}

.message {
  font-size: 0.75rem;
  color: var(--grey-color);
}

.message a {
  text-decoration: none;
  color: var(--primary-color-dark);
}

.copyright {
  font-size: 0.75rem;
  color: var(--grey-color);
  text-align: right;
}
