@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: theme('colors.primary.DEFAULT');
    --primary-light-color: theme('colors.primary.light');
    --primary-dark-color: theme('colors.primary.dark');
    --background-color: theme('colors.background.DEFAULT');
    --background-light-color: theme('colors.background.light');
    --background-dark-color: theme('colors.background.dark');
    --secondary-color: theme('colors.secondary.DEFAULT');
    --secondary-light-color: theme('colors.secondary.light');
    --secondary-dark-color: theme('colors.secondary.dark');
    --accent-color: theme('colors.accent.DEFAULT');
    --grey-color: theme('colors.grey.DEFAULT');
  }